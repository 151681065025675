




































import Component, { mixins } from "vue-class-component";
import ProgramDirectory from "./programs/Directory.vue";
import Calendar from "@/views/Calendar.vue";
import { Watch } from "vue-property-decorator";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import { LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { TabRoute } from "@/mixins/TabRoute";

@Component({
  name: "programs",
  components: {
    ProgramDirectory,
    Calendar,
    AppDialog
  }
})
export default class Programs extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  TabRoute
) {
  private isDialogOpen: boolean;
  private regularRoutes: Array<string>;
  height = "100%";

  get width(): string | number {
    if (this.isDesktop) {
      return innerWidth * 0.75;
    }
    return "100%";
  }

  get isDialogFullScreen(): boolean {
    return this.$route.path.includes("payment");
  }

  constructor() {
    super();
    this.isDialogOpen = false;
    this.regularRoutes = [
      "/programs",
      "/programs/directory",
      "/programs/calendar"
    ];
    if (this.regularRoutes.includes(this.$route.path)) {
      this.activeTab = this.$route.path;
    } else {
      this.activeTab = "/programs/directory";
    }
  }

  private shouldOpenPopup(path: string): boolean {
    return !this.regularRoutes.includes(path);
  }

  @Watch("$route.path")
  private watchRoute() {
    this.isDialogOpen = this.shouldOpenPopup(this.$route.path);
  }

  @Watch("isDialogOpen")
  private watchDialog() {
    if (this.isDialogOpen === false && this.shouldOpenPopup(this.$route.path)) {
      this.$router.back();
    }
  }

  mounted(): void {
    this.isDialogOpen = this.shouldOpenPopup(this.$route.path);
    const parentHeight =
      document.querySelector(".programs-container")?.clientHeight || 0;
    this.height = `${parentHeight - 48}px`;
  }
}
